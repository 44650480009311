import OrderItem from "./OrderItem";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  @media print {
    page-break-inside: auto;
  }
`;

const OrderItemList = ({ items }) => {
  const renderItem = (item) => {
    return <OrderItem key={item.item_id} item={item} />;
  };

  return <Wrapper>{items.map(renderItem)}</Wrapper>;
};

export default OrderItemList;
