import { Redirect, Route, Switch } from "react-router-dom";
import { storage } from "@sportnet/content/editor/storage";
import { withRouter } from "react-router";
import App from "../App";
import AppContext from "@sportnet/content/editor/appContext";
import Authorization from "../Authorization";
import CardsList from "../CardsList";
import CatalogsList from "../Catalogs/list";
import CategoryBrowser from "../Categories";
import Delivery from "../Options/Delivery";
import DeliveryDetail from "../Options/DeliveryDetail";
import Ecomail from "../Options/Ecomail";
import ExportItems from "../ExportItems";
import ExportOrders from "../ExportOrders";
import FeaturedPartners from "../FeaturedPartners";
import ImportDetail from "../ImportDetail";
import Imports from "../Imports";
import Mailchimp from "../Options/Mailchimp";
import OrderDetail from "../OrderDetail";
import OrderForwarding from "../OrderForwarding";
import OrderPrint from "../OrderPrint";
import Orders from "../Orders";
import Packetery from "../Options/Packetery";
import PartnersList from "../Partners/list";
import PriceLevelsList from "../PriceLevels/list";
import Product from "../Product";
import Products from "../Products";
import React from "react";
import ReceiptCreate from "../ReceiptCreate";
import ReceiptDetail from "../ReceiptDetail";
import ReceiptPrint from "../ReceiptPrint";
import ReceiptsList from "../ReceiptsList";
import RequestDetail from "../RequestDetail";
import Requests from "../Requests";
import SPS from "../Options/SPS";
import SaleChannelsList from "../SaleChannels/list";
import SalesChannelDetail from "../SalesChannelDetail";
import SalesChannelsCategoriesList from "../SalesChannelsCategories/list";

const Admin = ({ match }) => {
  return (
    <Authorization ppo={match?.params?.appspace}>
      <AppContext.Provider value={storage}>
        <App match={match}>
          <Switch>
            <Route exact path="/admin/:appspace/orders" component={Orders} />
            <Route
              exact
              path="/admin/:appspace/orders/:order_id?"
              component={OrderDetail}
            />
            <Route
              path="/admin/:appspace/orders/:order_id?/print"
              component={OrderPrint}
            />
            <Route
              exact
              path="/admin/:appspace/products"
              component={Products}
            />

            <Route
              path="/admin/:appspace/products/:product_id?"
              component={Product}
            />

            <Route
              exact
              path="/admin/:appspace/categories"
              component={CategoryBrowser}
            />
            <Route
              exact
              path="/admin/:appspace/requests"
              component={Requests}
            />
            <Route
              path="/admin/:appspace/requests/:supplier_id?/:request_id?"
              component={RequestDetail}
            />
            <Route
              exact
              path="/admin/:appspace/sale-channels"
              component={SaleChannelsList}
            />
            <Route
              path="/admin/:appspace/sale-channels/:salesChannelId"
              component={SalesChannelDetail}
            />
            <Route
              path="/admin/:appspace/price-levels"
              component={PriceLevelsList}
            />
            <Route path="/admin/:appspace/catalogs" component={CatalogsList} />
            <Route path="/admin/:appspace/partners" component={PartnersList} />
            <Route
              exact
              path="/admin/:appspace/delivery-options"
              component={Delivery}
            />
            <Route
              path="/admin/:appspace/delivery-options/:delivery_id?"
              component={DeliveryDetail}
            />
            <Route
              path="/admin/:appspace/mailchimp-options"
              component={Mailchimp}
            />
            <Route
              path="/admin/:appspace/packetery-options"
              component={Packetery}
            />
            <Route path="/admin/:appspace/sps-options" component={SPS} />
            <Route
              path="/admin/:appspace/ecomail-options"
              component={Ecomail}
            />
            <Route
              path="/admin/:appspace/order-forwarding"
              component={OrderForwarding}
            />
            <Route path="/admin/:appspace/cards" component={CardsList} />
            <Route
              path="/admin/:appspace/create-receipts"
              component={ReceiptCreate}
            />
            <Route
              exact
              path="/admin/:appspace/receipts-list"
              component={ReceiptsList}
            />
            <Route
              path="/admin/:appspace/receipt/:reciept_id?/print"
              component={ReceiptPrint}
            />
            <Route
              path="/admin/:appspace/receipt/:reciept_id?"
              component={ReceiptDetail}
            />

            <Route
              path="/admin/:appspace/sales-channels-categories"
              component={SalesChannelsCategoriesList}
            />
            <Route
              path="/admin/:appspace/imports/:importCategory"
              component={Imports}
            />
            <Route
              path="/admin/:appspace/import/:import_id"
              component={ImportDetail}
            />
            <Route
              path="/admin/:appspace/export-orders"
              component={ExportOrders}
            />
            <Route
              path="/admin/:appspace/export-items"
              component={ExportItems}
            />
            <Route
              path="/admin/:appspace/admin-partners"
              component={FeaturedPartners}
            />
            <Redirect
              from="/admin/:appspace/:path?"
              to="/admin/:appspace/orders"
            />
          </Switch>
        </App>
      </AppContext.Provider>
    </Authorization>
  );
};

export default withRouter(Admin);
