import { rem } from "polished";
import Button from "@sportnet/ui/Button";
import FormGroup from "@sportnet/ui/FormGroup";
import Input from "@sportnet/ui/Input";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { __ } from "../../utilities";

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${rem(10)};
`;

const ShipDetail = (props) => {
  const handleChange = (e) => {
    const { onChange } = props;
    const {
      target: { value, name },
    } = e;
    onChange(name, value);
  };

  return (
    <Wrapper>
      <FormGroup>
        <Input
          name="shipment_id"
          value={props.shipment_id}
          placeholder="Expedičné číslo"
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Input
          name="shipment_link"
          value={props.shipment_link}
          placeholder="Link na trackovanie balíka"
          onChange={handleChange}
        />
      </FormGroup>
      <ButtonWrapper>
        <Button primary onClick={props.onClick}>
          {__("Nastaviť expedičné údaje")}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

ShipDetail.propTypes = {
  shipment_link: PropTypes.string,
  shipment_id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

ShipDetail.defaultProps = {
  onChange: () => {},
  shipment_link: "",
  shipment_id: "",
};

export default ShipDetail;
