import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { __ } from "../../../utilities";
import { connect } from "react-redux";
import {
  countryCodesIsFetchingSelector,
  countryCodesListSelector,
} from "./selectors";
import { fetchCountryCodes } from "./actions";
import { rem } from "polished";
import { required } from "../../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Api from "../../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import Col, { Row } from "@sportnet/ui/Grid";
import DeliveryIcon from "../../../components/DeliveryIcon";
import FormField from "@sportnet/ui/FormField";
import Header from "@sportnet/ui/Header";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import Loader from "@sportnet/ui/Loader";
import Price from "@sportnet/ui/Price";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import config from "../../../config";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(8)};
`;

const Deliveries = styled.div`
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: ${rem(16)};
`;

const StyledForm = styled.form`
  height: 100dvh;
`;

const DeliveryDetail = ({
  history,
  dispatch,
  match: {
    params: { delivery_id },
  },
  countryCodes,
  isFetchingCountryCodes,
}) => {
  const { ppo } = useAuth();
  const [isFetchingDelivery, setIsFetchingDelivery] = React.useState(true);
  const [deliveryOptions, setDeliveryOptions] = React.useState([]);
  const [deliveryDetail, setDelivery] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState({
    value: "OTHER",
    label: "Iný",
  });

  React.useEffect(() => {
    dispatch(fetchCountryCodes());
    setIsFetchingDelivery(true);
    Api.adminGetSupplierDeliveryOptions(ppo)
      .then((response) => {
        const { items } = response;
        setDeliveryOptions(items);
        const obj = items[delivery_id];

        if (delivery_id !== "new") {
          setDelivery(obj);
          if (config.DELIVERY_TYPES.find((i) => i.value === obj.type)) {
            setSelectedType(
              config.DELIVERY_TYPES.find((i) => i.value === obj.type),
            );
          }
        } else {
          setDelivery({ volumetric_pricelist: [{}] });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsFetchingDelivery(false);
      });
  }, []);

  const handleCopy = () => {
    Api.adminUpdateDeliveryOptions(ppo, {
      data: {
        items: [
          ...deliveryOptions.map((i) => ({
            ...i,
            volumetric_pricelist: i.volumetric_pricelist.filter(
              (p) => Object.keys(p).length,
            ),
          })),
          {
            ...deliveryDetail,
            type: `${deliveryDetail.type}_COPY`,
            volumetric_pricelist: deliveryDetail.volumetric_pricelist.filter(
              (i) => Object.keys(i).length,
            ),
          },
        ],
      },
    })
      .then(() => {
        history.push(`/admin/${ppo}/delivery-options`);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 400) {
          if (error.response.name === "DUPLICATE_TYPE") {
            alert(__("Kópia tejto dopravy s dočasným typom už existuje"));
          } else {
            alert(__("Nesprávne vyplnená položka"));
          }
        } else {
          alert(
            delivery_id === "new"
              ? __("Pri vytváraní spôsobu dopravy nastala chyba")
              : __("Pri aktualizovaní spôsobu dopravy nastala chyba"),
          );
        }
      });
  };

  const handleRemove = () => {
    const arrayToSend = [...deliveryOptions];
    arrayToSend.splice(delivery_id, 1);
    const conf = window.confirm(__("Naozaj chcete odstrániť spôsob dopravy?"));

    if (conf) {
      Api.adminUpdateDeliveryOptions(ppo, { data: { items: arrayToSend } })
        .then(() => {
          history.push(`/admin/${ppo}/delivery-options`);
        })
        .catch((error) => {
          console.error(error);
          alert(__("Pri odstraňovaní spôsobu dopravy nastala nečakaná chyba"));
        });
    }
  };

  const handleSave = (values) => {
    const {
      type,
      name,
      creditpay,
      excludeFromFutbalnetShop,
      volumetric_pricelist,
      availableCountries,
      supportedPaymentMethods,
      free_shipping,
      price,
    } = values;

    const arrayToSend = [...deliveryOptions];

    const data = {
      type,
      name,
      creditpay,
      excludeFromFutbalnetShop,
      price: {
        amount: price.amount ?? 0,
        amount_vat_excl: price.amountVatExcl ?? 0,
        currency: price.currency || "EUR",
        vat_rate: price.vatRate ?? 20,
      },
      volumetric_pricelist: volumetric_pricelist?.reduce((all, p) => {
        const item = p;
        const w = parseFloat(p.weight);
        const v = parseFloat(p.volume);
        if (!Number.isNaN(w) && !Number.isNaN(v) && w > 0 && v > 0) {
          item.weight = w;
          item.volume = v;
          if (!p.price) {
            item.price = {
              amount: 0,
              amount_vat_excl: 0,
              vat_rate: 20,
              currency: "EUR",
            };
          }
          all.push(p);
        }
        return all;
      }, []),
      ...(availableCountries?.length && { availableCountries }),
      ...(supportedPaymentMethods?.length && { supportedPaymentMethods }),
    };

    if (free_shipping === "") {
      data.free_shipping_from = null;
    } else {
      data.free_shipping_from = parseFloat(free_shipping);
    }

    if (delivery_id === "new") {
      arrayToSend.push(data);
    } else {
      arrayToSend[delivery_id] = data;
    }

    setIsSubmitting(true);

    Api.adminUpdateDeliveryOptions(ppo, { data: { items: arrayToSend } })
      .then(() => {
        if (delivery_id === "new") {
          history.push(`/admin/${ppo}/delivery-options`);
        } else {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 400) {
          if (error.details.name === "DUPLICATE_TYPE") {
            alert(__("Doprava so zadaným typom už existuje"));
          } else {
            alert(__("Nesprávne vyplnená položka"));
          }
        } else {
          alert(
            delivery_id === "new"
              ? __("Pri vytváraní spôsobu dopravy nastala chyba")
              : __("Pri aktualizovaní spôsobu dopravy nastala chyba"),
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (isFetchingDelivery) {
    return <Loader />;
  }

  const title =
    delivery_id !== "new"
      ? `${__("Spôsob dopravy")}: ${deliveryDetail?.name}`
      : __("Nový spôsob dopravy");

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={{
        price: {
          amount: 0,
          amountVatExcl: 0,
          vatRate: 20,
          currency: "EUR",
        },
        creditpay: false,
        excludeFromFutbalnetShop: false,
        ...deliveryDetail,
        free_shipping_from:
          deliveryDetail.free_shipping_from === null ||
          typeof deliveryDetail.free_shipping_from === "undefined"
            ? ""
            : deliveryDetail.free_shipping_from,
      }}
      onSubmit={handleSave}
      render={({ handleSubmit, values, form: { change } }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Layout
            topFixed={
              <HeaderBar>
                <HeaderBar.Action
                  icon="back"
                  as={Link}
                  to={`/admin/${ppo}/delivery-options`}
                  title={__("Spôsoby dopravy")}
                />
                <HeaderBar.Header>{title}</HeaderBar.Header>
              </HeaderBar>
            }
            bottomFixed={
              <ContextBar>
                <ContextBarItem>
                  {delivery_id !== "new" && (
                    <Button danger onClick={handleRemove}>
                      {__("Odstrániť")}
                    </Button>
                  )}
                </ContextBarItem>
                <ContextBarSpacer />
                {delivery_id !== "new" && (
                  <>
                    <ContextBarItem>
                      <Button primary onClick={handleCopy}>
                        {__("Kopírovať")}
                      </Button>
                    </ContextBarItem>
                    &nbsp;
                  </>
                )}
                <ContextBarItem>
                  <Button primary={!success} success={!!success} type="submit">
                    {delivery_id === "new" ? (
                      <>{__("Pridať")}</>
                    ) : isSubmitting ? (
                      <Loader color={"#fff"} />
                    ) : success ? (
                      __("Uložené")
                    ) : (
                      __("Uložiť")
                    )}
                  </Button>
                </ContextBarItem>
              </ContextBar>
            }
          >
            {delivery_id !== "new" ? (
              <AppContext
                title={__(`${values.name}`)}
                breadcrumbs={[
                  {
                    url: `/admin/${ppo}/delivery-options`,
                    name: __("Spôsoby dopravy"),
                  },
                  { name: __(`${values.name}`) },
                ]}
              />
            ) : (
              <AppContext
                title={__(`${__("Nový spôsob dopravy")}`)}
                breadcrumbs={[
                  {
                    url: `/admin/${ppo}/delivery-options`,
                    name: __("Spôsoby dopravy"),
                  },
                  { name: __(`${__("Nový spôsob dopravy")}`) },
                ]}
              />
            )}
            <Segment>
              <Segment raised>
                <Deliveries>
                  {config.DELIVERY_TYPES.map((i) => (
                    <Button
                      basic
                      type="button"
                      primary={selectedType.value === i.value}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedType(i);
                        if (i.value !== "OTHER") {
                          change("type", i.value);
                          change("name", i.label);
                        } else {
                          change("type", "");
                          change("name", "");
                        }
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DeliveryIcon type={i.value} />
                      &nbsp;&nbsp; <div>{i.label}</div>
                    </Button>
                  ))}
                </Deliveries>
                <Row>
                  <Col xs={12} s={6} m={6}>
                    <Field validate={required} name="type">
                      {({
                        input: { value, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          label={__("Typ")}
                          required
                          name={name}
                          value={value}
                          disabled={selectedType.value !== "OTHER"}
                          onChange={onChange}
                          error={touched && error}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} s={6} m={6}>
                    <Field validate={required} name="name">
                      {({
                        input: { value, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          label={__("Názov")}
                          required
                          name={name}
                          value={value}
                          onChange={onChange}
                          error={touched && error}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} s={6}>
                    <Price
                      amount={values.price?.amount ?? 0}
                      amountVatExcl={values.price?.amountVatExcl ?? 0}
                      vatRate={values.price?.vatRate ?? 20}
                      onChange={(price) => {
                        change("price.amount", price.amount);
                        change("price.amountVatExcl", price.amountVatExcl);
                        change("price.vatRate", price.vatRate);
                        change("price.currency", price.currency);
                      }}
                    />
                  </Col>
                  <Col xs={12} s={6}>
                    <Field name="free_shipping">
                      {({
                        input: { value, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          type="number"
                          label={__("Doprava zdarma od")}
                          name={name}
                          placeholder="Doprava zdarma nad túto hranicu"
                          value={value}
                          onChange={onChange}
                          error={touched && error}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} s={6} m={6}>
                    <Field name="availableCountries">
                      {({
                        input: { value, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          type="theselectsimple"
                          label={__("Povolené krajiny")}
                          info={__(
                            "Ak nie je zvolená žiadna krajina, spôsob doručenia nie je geograficky obmmedzený.",
                          )}
                          name={name}
                          options={countryCodes}
                          loading={isFetchingCountryCodes}
                          value={value || []}
                          onChange={onChange}
                          error={touched && error}
                          multiple
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} s={6} m={6}>
                    <Field name="supportedPaymentMethods">
                      {({
                        input: { value, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          type="theselectsimple"
                          label={__("Povolené spôsoby platby")}
                          info={__(
                            "Ak si zákazník zvolí túto dopravu, objednávku bude možné uhradiť len vybranými spôsobmi platby. Ak nieje zvolený žiaden spôsob, platby niesú nijako obmedzené.",
                          )}
                          name={name}
                          options={config.PAYMENT_OPTIONS}
                          value={value || []}
                          onChange={onChange}
                          error={touched && error}
                          multiple
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} s={6} m={6}>
                    <Field name="creditpay" type="checkbox">
                      {({
                        input: { checked, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          type="checkbox"
                          label={__("Možné uhradiť kreditmi")}
                          name={name}
                          checked={checked}
                          onChange={onChange}
                          error={touched && error}
                        />
                      )}
                    </Field>
                    <Field name="excludeFromFutbalnetShop" type="checkbox">
                      {({
                        input: { checked, name, onChange },
                        meta: { touched, error },
                      }) => (
                        <FormField
                          type="checkbox"
                          label={__("Nepoužívať na futbalnet.shop")}
                          name={name}
                          checked={checked}
                          onChange={onChange}
                          error={touched && error}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Segment>
              <Segment raised>
                <Header size="xs">{__("Cenník prepravy")}</Header>
                {[
                  ...(values.volumetric_pricelist || []),
                  {
                    price: {
                      amount: 0,
                      amountVatExcl: 0,
                      currency: "EUR",
                      vatRate: 20,
                    },
                  },
                ].map((_, index) => {
                  return (
                    <Segment raised key={index.toString()}>
                      <Row>
                        <Col xs={12} s={6} m={3}>
                          <Field name={`volumetric_pricelist.${index}.weight`}>
                            {({
                              input: { value, name, onChange },
                              meta: { touched, error },
                            }) => (
                              <FormField
                                label={__("Hmotnosť do [kg]")}
                                name={name}
                                placeholder="kg"
                                value={value}
                                type="number"
                                onChange={(e) => {
                                  onChange(
                                    e.target.value
                                      .replace(/[^0-9,.]/, "")
                                      .replace(",", "."),
                                  );
                                }}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col xs={12} s={6} m={3}>
                          <Field name={`volumetric_pricelist.${index}.volume`}>
                            {({
                              input: { value, name, onChange },
                              meta: { touched, error },
                            }) => (
                              <FormField
                                label={__("Objem do [m3]")}
                                name={name}
                                placeholder="m3"
                                value={value}
                                type="number"
                                onChange={(e) => {
                                  onChange(
                                    e.target.value
                                      .replace(/[^0-9,.]/, "")
                                      .replace(",", "."),
                                  );
                                }}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col xs={12} s={6}>
                          <Flex>
                            <Field name={`volumetric_pricelist.${index}.price`}>
                              {({ input: { value, onChange } }) => (
                                <Price
                                  amount={value.amount || 0}
                                  amountVatExcl={value.amount_vat_excl || 0}
                                  vatRate={value.vat_rate || 20}
                                  onChange={(price) => {
                                    onChange({
                                      ...price,
                                      amount_vat_excl: price.amountVatExcl,
                                      vat_rate: price.vatRate,
                                    });
                                  }}
                                />
                              )}
                            </Field>
                            <Button
                              type="button"
                              style={{
                                pointer: "cursor",
                                marginTop: rem(8),
                                ...(index ===
                                  values.volumetric_pricelist.length && {
                                  pointerEvents: "none",
                                  opacity: 0,
                                }),
                              }}
                              icon="trash"
                              danger
                              onClick={() => {
                                change(
                                  `volumetric_pricelist`,
                                  values.volumetric_pricelist.filter(
                                    (_, i) => i !== index,
                                  ),
                                );
                              }}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    </Segment>
                  );
                })}
              </Segment>
            </Segment>
          </Layout>
        </StyledForm>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    countryCodes: countryCodesListSelector(state),
    isFetchingCountryCodes: countryCodesIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(DeliveryDetail);
