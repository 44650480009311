export default {
  APP_ID: "eshop",
  DELIVERY_TYPES: [
    {
      value: "ZASIELKOVNA",
      label: "Packeta",
    },
    {
      value: "DPD",
      label: "DPD",
    },
    {
      value: "BALIKOVO",
      label: "Balíkovo",
    },
    {
      value: "SPS",
      label: "Slovak Parcel Service",
    },
    {
      value: "SLOVAK_POST",
      label: "Slovenská pošta",
    },
    {
      value: "OO",
      label: "Osobný odber",
    },
    {
      value: "OTHER",
      label: "Iný",
    },
  ],
  PAYMENT_OPTIONS: [
    { value: "STANDARD_PAYMENT", label: "Platba kartou online" },
    { value: "PAY_AT_STORE", label: "Platba na predajni" },
    {
      value: "PAY_BY_TRANSFER",
      label: "Platba na účet (detaily prídu e-mailom)",
    },
    { value: "PAY_ON_DELIVERY", label: "Platba dobierkou" },
  ],
};
