import { Panel, PanelBody, PanelHeader } from ".";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __ } from "@sportnet/ui/utilities";
import { format } from "date-fns";
import { useAuth } from "@sportnet/auth-react";
import { useTheme } from "styled-components";
import BasicTable from "@sportnet/ui/BasicTable";
import Button from "@sportnet/ui/Button";
import Message from "@sportnet/ui/Message";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SportnetIcon from "@sportnet/ui/Icon";
import apiEshop from "../../service/apiEshop";
import paygateApi from "../../service/paygateApi";

const Payments = ({ detail, setInitialState, supplierId }) => {
  const { ppo, app } = useAuth();
  const [orderPaygatePayments, setOrderPaygatePayments] = React.useState([]);
  const [orderPaygatePaymentsLoading, setOrderPaygatePaymentsLoading] =
    React.useState(false);
  const [orderPaygatePaymentsError, setOrderPaygatePaymentsError] =
    React.useState(null);
  const [isRefundSubmitting, setIsRefundSubmitting] = React.useState(false);
  const [isRefunding, setIsRefunding] = React.useState(false);
  const theme = useTheme();

  const loadPaygatePayments = React.useCallback(() => {
    setOrderPaygatePaymentsLoading(true);
    setOrderPaygatePaymentsError(null);

    Promise.all([
      paygateApi
        .getPaymentsByExternalId(app, ppo, `O-${detail._id}`)
        .then(({ items }) => {
          return items;
        }),
      paygateApi
        .getPaymentsByExternalId(app, ppo, `P-${detail.purchase_id}`)
        .then(({ items }) => {
          return items;
        }),
    ])
      .then((ii) => ii.reduce((r, i) => [...r, ...i]))
      .then((payments) =>
        setOrderPaygatePayments(
          payments.sort((a, b) => (a.createdDT < b.createdDT ? -1 : 1)),
        ),
      )
      .catch((e) => {
        if (e.details.statusCode === 401) {
          setOrderPaygatePaymentsError("Nemáte oprávnenie na platobný modul");
        } else {
          setOrderPaygatePaymentsError(`Neznáma chyba: ${e.name}`);
        }
      })
      .finally(() => {
        setOrderPaygatePaymentsLoading(false);
      });
  }, [ppo, app, detail.purchase_id, detail._id]);

  const refundPaygatePayment = async (paymentId) => {
    if (ppo && window.confirm(__("Skutočne si želáte refundovať platbu?"))) {
      try {
        setIsRefundSubmitting(true);

        await paygateApi.refundPayment(app, ppo, paymentId, {
          body: { amount: detail.payment_price.amount },
        });

        loadPaygatePayments(detail);
      } catch (e) {
        alert(__("Platbu sa nepodarilo refundovať."));
        console.error(e);
      } finally {
        setIsRefundSubmitting(false);
      }
    }
  };

  const handleRefund = (orderNumber) => {
    const conf = window.confirm(__("Skutočne si želáte refundovať platbu?"));

    if (conf) {
      setIsRefunding(true);
      apiEshop
        .gpwebpayRefundPayment(supplierId, {
          orderNumber,
        })
        .then(() => {
          setInitialState();
        })
        .catch((e) => {
          if (e.details?.name === "ACL_FORBIDDEN") {
            alert("Nemáte oprávnenie na refundáciu");
          } else {
            alert("Platbu sa nepodarilo refundovať");
          }
        })
        .finally(() => {
          setIsRefunding(false);
        });
    }
  };

  React.useEffect(() => {
    loadPaygatePayments();
  }, [loadPaygatePayments]);

  return (
    <Panel>
      <PanelHeader>{__("Pokusy o úhradu:")}</PanelHeader>
      <PanelBody>
        <Segment loading={!!orderPaygatePaymentsLoading} paddingSize={"xxxs"}>
          {!!orderPaygatePayments && (
            <BasicTable
              rows={orderPaygatePayments}
              renderRow={(i) => {
                const totalRefunds = (i.refunds || []).reduce(
                  (rr, r) => rr + r.amount,
                  0,
                );
                return [
                  <span title={i._id}>
                    {i.paygate.type} / {i.vs}
                  </span>,
                  format(new Date(i.createdDT), "dd.MM.yyyy HH:mm"),
                  <div style={{ display: "flex" }}>
                    <SportnetIcon
                      name={
                        i.status === "OK" || i.status === "REFUNDED"
                          ? "check"
                          : "close"
                      }
                      color={i.status === "OK" ? "green" : "red"}
                    />
                    &nbsp;
                    {i.amount.toFixed(2)} {i.currency}
                  </div>,
                  <>
                    {!!i.refunds && i.refunds.length && (
                      <>
                        {i.refunds.map((refund) => {
                          return (
                            <div>
                              {format(
                                new Date(refund.refundedDT),
                                "dd.MM.yyyy HH:mm",
                              )}{" "}
                              - {refund.who.displayName} -{" "}
                              {refund.amount.toFixed(2)} {i.currency}
                            </div>
                          );
                        })}
                      </>
                    )}
                    {totalRefunds < i.amount &&
                      (i.status === "OK" || i.status === "REFUNDED") && (
                        <Button
                          loading={isRefundSubmitting}
                          disabled={isRefundSubmitting}
                          onClick={() => {
                            refundPaygatePayment(i._id);
                          }}
                          primary
                        >
                          {__("Refundovať")}
                        </Button>
                      )}
                  </>,
                ];
              }}
              columns={[
                { header: __("Platobná brána / VS") },
                { header: __("Dátum") },
                { header: __("Zaplatené") },
                { header: __("Refundácia") },
              ]}
              rowKey="vs"
            />
          )}
          {!!orderPaygatePaymentsError && (
            <Message danger>{orderPaygatePaymentsError}</Message>
          )}
        </Segment>
        {!!detail.gpwebpay_ordernumber &&
          !!detail.gpwebpay_ordernumber.length && (
            <Table>
              <Thead>
                <Tr>
                  <Th>{__("Číslo platby")}</Th>
                  <Th>{__("Dátum")}</Th>
                  <Th>{__("Zaplatené")}</Th>
                  <Th>{__("Refundácia")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!!detail.gpwebpay_ordernumber &&
                  !!detail.gpwebpay_ordernumber.map(
                    ({
                      nr,
                      created_date: createdDT,
                      refundDT,
                      refundAmount,
                      refundedBy,
                      paid_payload: currentAttemptPayload,
                    }) => {
                      return (
                        <Tr key={nr.toString()}>
                          <Td>{nr}</Td>
                          <Td>
                            {format(new Date(createdDT), "dd.MM.yyyy HH:mm")}
                          </Td>
                          <Td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {(currentAttemptPayload ||
                                detail.paid_payload?.gpwebpay_ordernumber ===
                                  nr) &&
                              detail.paid_date ? (
                                <>
                                  <SportnetIcon
                                    name="check"
                                    size="l"
                                    color={theme.color.success}
                                  />
                                  {" zaplatené "}
                                  {format(
                                    new Date(detail.paid_date),
                                    "dd.MM.yyyy HH:mm",
                                  )}
                                </>
                              ) : (
                                <>
                                  <SportnetIcon
                                    name="close"
                                    size="l"
                                    color={theme.color.danger}
                                  />
                                  {" nezaplatené "}
                                </>
                              )}
                            </div>
                          </Td>
                          <Td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {(currentAttemptPayload ||
                                detail.paid_payload?.gpwebpay_ordernumber ===
                                  nr) &&
                                detail.paid_date &&
                                (refundDT ? (
                                  <>
                                    <SportnetIcon
                                      name="check"
                                      size="l"
                                      color={theme.color.success}
                                    />{" "}
                                    {`${(refundAmount / 100).toFixed(2)} €, `}{" "}
                                    {format(
                                      new Date(refundDT),
                                      "dd.MM.yyyy HH:mm",
                                    )}
                                    {" - "}
                                    {refundedBy?.displayName}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      loading={isRefunding}
                                      disabled={isRefunding}
                                      primary
                                      onClick={() => {
                                        handleRefund(nr);
                                      }}
                                    >
                                      {__("Refundovať")}
                                    </Button>
                                  </>
                                ))}
                            </div>
                          </Td>
                        </Tr>
                      );
                    },
                  )}
              </Tbody>
            </Table>
          )}
      </PanelBody>
    </Panel>
  );
};

export default Payments;
