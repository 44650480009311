import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, getProp } from "../../utilities";
import { connect } from "react-redux";
import { fetchGroupsList, fetchPartnersList } from "./actions";
import { fetchPriceLevelsList } from "../PriceLevels/actions";
import { formatUserName } from "@sportnet/utilities";
import {
  groupsSelector,
  partnersIsFetchingSelector,
  partnersListSelector,
} from "./selectors";
import { priceLevelsListSelector } from "../PriceLevels/selectors";
import { rem } from "polished";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import AsyncSelect from "@sportnet/ui/AsyncSelect";
import Button from "@sportnet/ui/Button";
import DefaultAvatar from "@sportnet/ui/assets/DefaultAvatar.svg";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Image from "@sportnet/ui/Image";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import Loader from "@sportnet/ui/Loader";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Select from "@sportnet/ui/Select";
import Sidebar from "@sportnet/ui/Sidebar";
import apiEshop from "../../service/apiEshop";
import apiSportnet from "../../service/apiSportnet";
import styled from "styled-components";

//TODO - ORGANIZATION

const UserAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(14)};
  height: 100%;
`;

const AvatarImage = styled(Image)`
  border-radius: 50%;
`;

const PartnersList = ({
  dispatch,
  priceLevels,
  isFetching,
  partners,
  groups,
}) => {
  const { ppo } = useAuth();
  const [update, setUpdate] = React.useState(false);
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [businessId, setBusinessId] = React.useState("");
  const [groupId, setGroupId] = React.useState("");
  const [form, setForm] = React.useState(null);
  const [pricelevel, setPricelevel] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [allowedUsers, setAllowedUsers] = React.useState([]);
  const [updating, setUpdating] = React.useState(false);
  const [updatingCompany, setUpdatingCompany] = React.useState(true);
  const [type, setType] = React.useState("company");

  React.useEffect(() => {
    dispatch(fetchPartnersList(ppo));
    dispatch(fetchPriceLevelsList(ppo));
  }, [ppo]);

  const findPriceLevel = (code) => {
    const priceLevel = priceLevels.find((item) => {
      return item._id === code;
    });
    if (priceLevel) {
      return priceLevel.name;
    }
    return null;
  };

  const searchByBusinessId = () => {
    setLoading(true);
    let data = null;
    apiEshop
      .adminGetB2BPartner(ppo, businessId.replace(/\s+/g, ""))
      .then((res) => {
        if (res.payload) {
          data = res.payload.businessInfo.organization;
        } else {
          alert(__("Organizácia už je v zozname"));
          data = form;
        }
      })
      .catch(async (e) => {
        if (
          e.details.name === "B2BPARTNER_NOT_FOUND" &&
          e.details.payload.businessInfo
        ) {
          data = e.details.payload.businessInfo.organization;
          setUpdatingCompany(true);
          setUpdating(true);
        } else {
          alert(__("Organizáciu nebolo možné nájsť."));
          console.error(e);
        }
      })
      .finally(() => {
        setForm(data);
        setLoading(false);
      });
  };
  const submitForm = () => {
    const fullName = form?.full_name;
    const formattedAllowedUsers =
      allowedUsers.length <= 0
        ? null
        : allowedUsers.map(({ displayName, sportnetId }) => ({
            displayName,
            sportnetId,
          }));
    if (!(businessId || groupId)) {
      alert(
        __("Vyhľadajte prosím spoločnosť pomocou IČO alebo vyberte skupinu!"),
      );
    } else if (pricelevel === "") {
      alert(__("Vyberte prosím cenovú hladinu"));
    } else {
      setLoading(true);
      if (update) {
        apiEshop
          .adminUpdateB2BPartner(ppo, businessId || groupId, {
            data: {
              pricelevel,
              allowedUsers: formattedAllowedUsers,
              name: fullName,
              type,
            },
          })
          .then(() => {
            resetState();
            dispatch(fetchPartnersList(ppo));
          })
          .catch((err) => {
            setLoading(false);
            alert(__("Partnera sa nepodarilo uložiť"));
          });
      } else {
        const data = {
          name: fullName,
          pricelevel,
          allowedUsers: formattedAllowedUsers,
        };
        if (businessId) {
          data.businessId = businessId;
          data.type = "company";
        } else {
          data.groupId = groupId;
          data.type = "group";
        }
        apiEshop
          .adminCreateNewB2BPartner(ppo, {
            data: { ...data },
          })
          .then(() => {
            resetState();
            dispatch(fetchPartnersList(ppo));
          })
          .catch((err) => {
            setLoading(false);
            const error = err.details;
            let msg = "";
            if (error) {
              switch (error.name) {
                case "B2BPARTNER_EXISTS":
                  msg = __(`Partner so zadaným ID už existuje.`);
                  break;
                default:
                  msg = error.description || "";
              }
            }
            alert(`${__("Partnera sa nepodarilo uložiť")}\n${msg}`);
          });
      }
    }
  };

  const deletePartner = (id, type) => {
    if (window.confirm(__("Skutočne si želáte odstrániť partnera?"))) {
      if (type === "company") {
        apiEshop
          .adminDeleteB2BPartner(ppo, id)
          .then(() => {
            dispatch(fetchPartnersList(ppo));
          })
          .catch(() => {
            alert(__("Partnera sa nepodarilo zmazať"));
          });
      } else if (type === "group") {
        apiEshop
          .adminDeleteGroupPartner(ppo, id)
          .then(() => {
            dispatch(fetchPartnersList(ppo));
          })
          .catch(() => {
            alert(__("Partnera sa nepodarilo zmazať"));
          });
      }
    }
  };

  const getUserOption = ({ displayName, sportnetId }) => {
    return {
      value: sportnetId,
      label: `${displayName} (${sportnetId})`,
      displayName,
      sportnetId,
      icon: (
        <UserAvatarWrapper>
          <AvatarImage
            alt={displayName}
            fallbackSrc={DefaultAvatar}
            src={`${apiSportnet.baseUrl}/user-photo/${sportnetId}.jpg`}
            height={14}
            width={14}
          />
        </UserAvatarWrapper>
      ),
    };
  };

  const resetState = () => {
    setUpdate(false);
    setSidebarVisible(false);
    setBusinessId("");
    setForm(null);
    setPricelevel("");
    setLoading(false);
    setAllowedUsers([]);
    setUpdatingCompany(true);
    setUpdating(false);
    setGroupId(null);
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Zoznam partnerov")}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              onClick={() => {
                setUpdate(false);
                setSidebarVisible(true);
                setBusinessId("");
                setForm(null);
                setPricelevel("");
                setLoading(false);
                setAllowedUsers([]);
                setUpdatingCompany(true);
                setUpdating(false);
                setGroupId(null);
                dispatch(fetchGroupsList(ppo));
              }}
            >
              {__("Pridať partnera")}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Zoznam partnerov")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__("Partner / Skupina")}</Th>
                <Th>{__("Cenová hladina")}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {partners.map((partner) => {
                const { name, businessId, pricelevel, allowedUsers, groupId } =
                  partner;
                return (
                  <Tr
                    key={businessId || groupId}
                    onClick={() => {
                      dispatch(fetchGroupsList(ppo));
                      setUpdate(businessId || groupId);
                      setSidebarVisible(true);
                      setForm({ full_name: name });
                      setPricelevel(pricelevel);
                      setBusinessId(businessId);
                      setGroupId(groupId);
                      setAllowedUsers((allowedUsers || []).map(getUserOption));
                      setUpdating(true);
                      setUpdatingCompany(businessId ? true : false);
                      setType(partner.type ? partner.type : "company");
                    }}
                  >
                    <Td>
                      {name} (
                      {businessId ? `IČO: ${businessId}` : `id: "${groupId}"`})
                    </Td>
                    <Td>{findPriceLevel(pricelevel)}</Td>
                    <Td center>
                      <Button
                        danger
                        icon="close"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (partner.businessId) {
                            deletePartner(businessId, "company");
                          } else {
                            deletePartner(groupId, "group");
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>

      <Sidebar
        visible={sidebarVisible}
        header={__("Partner")}
        onClose={() => {
          resetState();
        }}
      >
        <div style={{ position: "relative" }}>
          <Segment>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  background: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader size="xl" />
              </div>
            )}

            {(!updating || (updating && updatingCompany)) && (
              <FormGroup>
                <Label>{__("IČO")}</Label>
                <div style={{ position: "relative" }}>
                  <Input
                    name="business_id"
                    value={businessId || ""}
                    onChange={(e) => {
                      setBusinessId(e.target.value);
                    }}
                    placeholder={__(
                      "Zadajte IČO alebo zvoľte skupinu používateľov",
                    )}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <Button
                      onClick={searchByBusinessId}
                      primary
                      icon="search"
                    />
                  </div>
                </div>
              </FormGroup>
            )}
            {(!updating || (updating && !updatingCompany)) && (
              <FormGroup>
                <Label>{__("Skupina")}</Label>
                <Select
                  name="groups"
                  disabled={!!businessId}
                  value={groupId || ""}
                  onChange={(e) => {
                    setGroupId(e.target.value);
                    setForm({
                      full_name: e.target.value,
                    });
                    setUpdating(true);
                    setUpdatingCompany(false);
                  }}
                >
                  <option />
                  {groups.data &&
                    groups.data.map((item) => {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                </Select>
              </FormGroup>
            )}

            {form && (
              <div>
                <FormGroup>
                  <Label>
                    {businessId ? __("Obchodné meno") : __("Názov")}
                  </Label>
                  <Input
                    disabled={businessId ? true : false}
                    value={getProp(form, ["full_name"])}
                    onChange={(e) => {
                      setForm({
                        full_name: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{__("Cenová hladina")}</Label>
                  <Select
                    name="pricelevel"
                    value={pricelevel}
                    onChange={(e) => {
                      setPricelevel(e.target.value);
                    }}
                  >
                    <option />
                    {priceLevels.map((item) => {
                      return (
                        <option
                          value={item._id}
                          selected={item._id === pricelevel}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormGroup>

                {false && (
                  <FormGroup>
                    <Label>{__("Povolený používatelia")}</Label>
                    <AsyncSelect
                      name="allowedUsers"
                      value={allowedUsers}
                      multiple
                      onChange={(newValue) => {
                        setAllowedUsers(newValue);
                      }}
                      placeholder={__("Zadajte Sportnet ID používateľa")}
                      defaultOptions={allowedUsers}
                      loadOptions={async (inputValue) => {
                        try {
                          if (inputValue.length === 24) {
                            const option = allowedUsers.find(
                              (o) => o.value === inputValue,
                            );
                            if (option) {
                              return [option];
                            } else {
                              const user =
                                await apiSportnet.getPublicUserProfile(
                                  inputValue,
                                );
                              const displayName = formatUserName(user);
                              return [
                                getUserOption({
                                  displayName,
                                  sportnetId: user._id,
                                }),
                              ];
                            }
                          }
                        } catch (e) {
                          alert(__("Používateľa sa nepodarilo nájsť"));
                        }
                        return [];
                      }}
                    />
                  </FormGroup>
                )}

                <Button primary onClick={submitForm}>
                  {__("Uložiť")}
                </Button>
              </div>
            )}
          </Segment>
        </div>
      </Sidebar>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    priceLevels: priceLevelsListSelector(state),
    partners: partnersListSelector(state),
    isFetching: partnersIsFetchingSelector(state),
    groups: groupsSelector(state),
  };
};

export default connect(mapStateToProps)(PartnersList);
