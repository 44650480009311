import { Field, Form } from "react-final-form";
import { __ } from "../../../utilities";
import { useAuth } from "@sportnet/auth-react";
import ApiEshop from "../../../service/apiEshop";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SubmitButton from "@sportnet/ui/Button/Submit";

const SPS = () => {
  const { ppo } = useAuth();
  const [isFetchingSettings, setIsFetchingSettings] = React.useState(true);
  const [spsSettings, setSpsSettings] = React.useState();

  React.useEffect(() => {
    (async () => {
      setIsFetchingSettings(true);
      try {
        const newSettings = await ApiEshop.adminGetSupplierSPS(ppo);
        newSettings.notifytype = newSettings.notifytype.toString();
        setSpsSettings(newSettings);
      } catch (e) {
        console.error(e);
        alert(__("Nemáte právo upravovať SPS"));
      } finally {
        setIsFetchingSettings(false);
      }
    })();
  }, [ppo]);

  const handleSave = async (data) => {
    try {
      const { notifytype } = data;
      if (notifytype) {
        data.notifytype = parseInt(notifytype);
      }
      const newSettings = await ApiEshop.adminUpdateSPS(ppo, {
        data,
      });
      setSpsSettings(newSettings);
    } catch (e) {
      console.error(e);
      alert(__("Uloženie nastavení SPS sa nepodarilo"));
    }
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Nastavenia SPS")}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      <Form
        initialValues={spsSettings}
        onSubmit={handleSave}
        render={({ handleSubmit, submitFailed, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Segment noBottomGutter>
              <Segment raised loading={isFetchingSettings}>
                <Row>
                  <Col xs={12} m={6}>
                    <Field name="apiLogin">
                      {({ input: { value, onChange, name } }) => (
                        <FormField
                          label={__("API login")}
                          name={name}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} m={6}>
                    <Field name="apiPassword">
                      {({ input: { value, onChange, name } }) => (
                        <FormField
                          label={__("API heslo")}
                          name={name}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} m={6}>
                    <Field name="insurvalue">
                      {({ input: { value, onChange, name } }) => (
                        <FormField
                          label={__("Hodnota poistenia zásielky (€)")}
                          name={name}
                          value={value}
                          type="number"
                          onChange={(e) => {
                            const val = e.target.value
                              .replace(/[^0-9,.]/, "")
                              .replace(",", ".");

                            if (!isNaN(val)) {
                              onChange(parseInt(val));
                            } else {
                              onChange(val);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} m={6}>
                    <Field name="notifytype">
                      {({ input: { value, onChange, name } }) => (
                        <FormField
                          type="theselectsimple"
                          label={__("Typ notifikácie")}
                          name={name}
                          options={[
                            { label: "Žiadna notifikácia", value: "0" },
                            { label: "E-mailová notifikácia", value: "1" },
                            { label: "SMS notifikácia", value: "2" },
                            {
                              label: "E-mailová aj SMS notifikácia",
                              value: "3",
                            },
                          ]}
                          value={value || []}
                          onChange={onChange}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <SubmitButton
                  isError={submitFailed}
                  isSubmitting={submitting}
                  successText={__("Uložené!")}
                >
                  {__("Uložiť")}
                </SubmitButton>
              </Segment>
            </Segment>
          </form>
        )}
      />
    </Layout>
  );
};

export default SPS;
