import { Link } from "react-router-dom";
import { Row, default as SportnetCol } from "@sportnet/ui/Grid";
import { StyledStatus } from "../Orders";
import { __, getProp } from "../../utilities";
import { connect } from "react-redux";
import {
  fetchCodelistOrderStatus,
  fetchOrderDetail,
  setActiveOrderId,
} from "../Orders/actions";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { format } from "date-fns";
import { getOrderByIdSelector } from "../Orders/selectors";
import { orderItemPropTypes } from "./OrderItem";
import { rem } from "polished";
import { saleChanellsListSelector } from "../SaleChannels/selectors";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Body from "../../components/Body";
import Button from "@sportnet/ui/Button";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import Modal, { ModalActions, ModalContent } from "@sportnet/ui/Modal";
import OrderItemList from "./OrderItemList";
import OrderSummary from "../../components/OrderSummary";
import PaymentInfo from "./PaymentInfo";
import Payments from "./Payments";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ShipDetail from "../../components/ShipDetail";
import Statuses from "../Orders/Statuses";
import TheSelect from "@sportnet/ui/TheSelectSimple";
import apiEshop from "../../service/apiEshop";
import styled, { css, withTheme } from "styled-components";

export const PanelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  ${({ theme }) => theme.eshopMedia.md`
    justify-content: space-between;
    flex-direction: row;
  `};
  @media print {
    flex-direction: row;
  }
`;

const Col = styled.div`
  flex: 1;
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColorLighter};
  padding: 0 ${rem(15)};
  margin: ${rem(10)} 0;
  ${({ theme }) => theme.eshopMedia.md`
    max-width: calc(33% - ${rem(10)});
    flex: 0 0 calc(33% - ${rem(10)});
  `};
  @media print {
    margin: ${rem(3)};
    padding: 0 ${rem(8)};
    max-width: calc(50% - ${rem(10)});
    flex: 0 0 calc(50% - ${rem(10)});
    display: ${({ hide }) => (hide ? "none" : "initial")};
  }
`;

export const ColButton = styled(Col)`
  border: none;
`;

export const Panel = styled(Col)`
  max-width: 100%;
  ${({ theme }) => theme.eshopMedia.md`
    max-width: 100%;
  `};
  border: ${({ noBorders }) =>
    noBorders
      ? "none"
      : `${rem(1)} solid ${({ theme }) => theme.separatorColorLighter}`};

  @media print {
    order: ${({ order }) => (order ? "-3" : "")};
    order: ${({ orderSupplier }) => (orderSupplier ? "-2" : "")};
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${rem(10)};
`;

const NotePanel = styled(Panel)`
  max-width: calc(100% - ${rem(10)});
  flex: 0 0 calc(100% - ${rem(10)});
`;

const TitleHeader = styled.div`
  display: inline-block;
  font-weight: bold;
`;

export const PanelHeader = styled(TitleHeader)`
  display: block;
  padding: ${rem(15)} 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColorLighter};

  @media print {
    padding: ${rem(8)} 0;
  }
`;

export const PanelBody = styled.div`
  padding: ${rem(15)} 0;
  line-height: ${rem(25)};

  @media print {
    line-height: ${rem(18)};
    padding: ${rem(8)} 0;
  }
`;

const OrderId = styled(PanelBody)`
  display: none;
  @media print {
    display: block;
    font-size: ${rem(18)};
    margin: ${rem(10)} 0;
  }
`;

const TotalPrice = styled.div`
  padding: ${rem(15)} 0;
  text-align: right;
`;

const StatusLog = styled.div`
  display: flex;
  padding: ${rem(10)} 0;
  ${({ last }) =>
    !last &&
    css`
      border-bottom: ${rem(1)} solid
        ${({ theme }) => theme.separatorColorLighter};
    `};
  flex-direction: column;
  ${({ theme }) => theme.eshopMedia.sm`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  `};
`;

const StatusLogText = styled.div`
  flex-basis: 33%;
`;

const SystemLog = styled.div`
  padding: ${rem(10)} 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColorLighter};
  ${({ theme }) => theme.eshopMedia.sm`
    align-items: center;
    justify-content: space-between;
  `};
  color: red;
`;

const JsonDisplay = styled.div`
  padding: ${rem(10)};
  background: #f0f0f0;
  box-shadow: inset 0 0 5px #aaaaaa;
  white-space: pre-wrap;
  font-family: monospace;
  margin-top: ${rem(10)};
  color: black;
`;

const PaidWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const packeteryLabelFormats = [
  {
    label: __("A6 na A6"),
    value: "A6 on A6",
  },
  {
    label: __("A7 na A7"),
    value: "A7 on A7",
  },
  {
    label: __("A6 na A4"),
    value: "A6 on A4",
  },
  {
    label: __("A7 na A4"),
    value: "A7 on A4",
  },
  {
    label: __("A8 na A8"),
    value: "A8 on A8",
  },
  {
    label: __("105x35mm na A4"),
    value: "105x35mm on A4",
  },
];

class OrderDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shipment_id: "",
      shipment_link: "",
      receiptDetail: {},
      fetchingPacketeryLabel: false,
      isPacketeryLabelModalOpened: false,
      isRefunding: false,
      packeteryLabelFormat: "A6 on A6",
      isRefundSubmitting: null,
    };

    this.getOrderDetail = this.getOrderDetail.bind(this);
    this.handleClickExportPDF = this.handleClickExportPDF.bind(this);
    this.handleClickConfirmationPDF =
      this.handleClickConfirmationPDF.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.renderStatusLogItem = this.renderStatusLogItem.bind(this);
    this.handleClickPrint = this.handleClickPrint.bind(this);
    this.handleChangeEx = this.handleChangeEx.bind(this);
    this.handleSubmitShip = this.handleSubmitShip.bind(this);
    this.onOrderSetPaid = this.onOrderSetPaid.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.order_id !== this.props.match.params.order_id) {
      this.getOrderDetail(this.props);
    }
    if (
      getProp(prevProps.detail, ["shipment_id"]) !==
      getProp(this.props.detail, ["shipment_id"])
    ) {
      this.setState({
        shipment_id: getProp(this.props.detail, ["shipment_id"]),
      });
    }
    if (
      getProp(prevProps.detail, ["shipment_link"]) !==
      getProp(this.props.detail, ["shipment_link"])
    ) {
      this.setState({
        shipment_link: getProp(this.props.detail, ["shipment_link"]),
      });
    }
  }

  onOrderSetPaid() {
    if (
      window.confirm(__("Skutočne si prajete potvrdiť zaplatenie objednávky?"))
    ) {
      apiEshop
        .adminSetOrderPaid(this.props.supplierId, this.props.detail._id, {
          data: {},
        })
        .then(() => {
          this.setInitialState();
        })
        .catch((e) => {
          alert(`Chyba: ${e}`);
        });
    }
  }

  getOrderDetail(props = this.props) {
    const {
      dispatch,
      acl,
      match: {
        params: { order_id, appspace },
      },
    } = props;
    dispatch(fetchOrderDetail(order_id))
      .then((detail) => {
        if (detail.receipt_id) {
          if (acl.adminReceiptDetail) {
            apiEshop
              .adminReceiptDetail(detail.receipt_id, appspace)
              .then((response) => {
                this.setState({
                  receiptDetail: response,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            apiEshop
              .adminSupplierReceiptDetail(detail.receipt_id, appspace)
              .then((response) => {
                this.setState({
                  receiptDetail: response,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
        // nacitame platby
        // this.loadPaygatePayments(detail);
      })
      .catch(() => {
        alert(__("Objednávku sa nepodarilo nájsť alebo k nej nemáte prístup"));
        this.props.history.push(`/admin/${appspace}/orders`);
      });
  }

  getStatusObj(orderStatuses, status) {
    return orderStatuses.find((item) => item.value === status) || {};
  }

  getSaleChannelLabel(id) {
    const channel = this.props.saleChannels.find((i) => {
      return i._id === id;
    });
    if (channel) {
      return channel.name;
    }
    return id;
  }

  setInitialState() {
    const {
      dispatch,
      detail,
      orderStatuses,
      match: {
        params: { order_id: orderId, appspace },
      },
    } = this.props;
    dispatch(setActiveOrderId(orderId));
    this.setState({
      shipment_id: getProp(detail, ["shipment_id"]),
      shipment_link: getProp(detail, ["shipment_link"]),
    });
    if (appspace) {
      dispatch(fetchSaleChannelsList(appspace)).then(() => {
        this.getOrderDetail();
        if (orderStatuses.length === 0) {
          dispatch(fetchCodelistOrderStatus());
        }
      });
    }
  }

  handleClickPrint() {
    const {
      match: {
        params: { order_id: orderId, appspace },
      },
    } = this.props;
    window.open(`/admin/${appspace}/orders/${orderId}/print`);
  }

  handleRefund(orderNumber) {
    const { supplierId } = this.props;
    const conf = window.confirm(__("Skutočne si želáte refundovať platbu?"));

    if (conf) {
      this.setState({ isRefunding: true });
      apiEshop
        .gpwebpayRefundPayment(supplierId, {
          orderNumber,
        })
        .then(() => {
          this.setInitialState();
          this.setState({ isRefunding: false });
        })
        .catch((e) => {
          if (e.details?.name === "ACL_FORBIDDEN") {
            alert("Nemáte oprávnenie na refundáciu");
          } else {
            alert("Platbu sa nepodarilo refundovať");
          }
          this.setState({ isRefunding: false });
        });
    }
  }

  handleSubmitShip() {
    const { supplierId } = this.props;
    const { params } = this.props.match;
    const data = {};
    if (this.state.shipment_id) {
      data.shipment_id = this.state.shipment_id;
    }
    if (this.state.shipment_link) {
      data.shipment_link = this.state.shipment_link;
    }

    apiEshop
      .adminChangeOrderShipmentInfo(supplierId, params.order_id, {
        data,
      })
      .then(() => {
        this.getOrderDetail();
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          alert(__("Nemáte právo na zmenu expedičných údajov."));
        } else {
          alert(__("Nastala neočakávaná chyba."));
        }
      });
  }

  handleChangeEx(name, value) {
    this.setState({ [name]: value });
  }

  handleChangeStatus(e) {
    const {
      supplierId,
      detail: { _id: orderId },
    } = this.props;
    const { value: status } = e.target;
    const data = {};
    if (status === "storno") {
      data.reason = prompt(
        __("Zadajte číslo dobropisu alebo dôvod stornovania"),
      );
    }
    const conf = window.confirm(__("Naozaj chcete zmeniť stav objednávky?"));
    if (conf) {
      if (
        status === "shipped" &&
        this.state.shipment_id === "" &&
        this.state.shipment_link === ""
      ) {
        const shippedConf = window.confirm(
          __("Expedičné údaje neboli zadané. Prajete si naďalej pokračovať?"),
        );
        if (!shippedConf) {
          return null;
        }
      }
      apiEshop
        .adminChangeOrderStatus(supplierId, orderId, status, { data })
        .then(() => {
          this.setInitialState();
        })
        .catch((error) => {
          const {
            details: { statusCode, name },
          } = error;
          if (statusCode === 403 && name === "STATUS_CHANGE_DENIED") {
            alert(__("Ľuťujeme, nemáte právo zmeniť stav objednávky"));
          } else if (
            statusCode === 409 &&
            name === "STATUS_PAID_REQUIRED_CONFLICT"
          ) {
            alert(
              __(
                "Na zmenu stavu je potrebné aby bola objednávka označená ako uhradená.",
              ),
            );
          } else if (statusCode === 409 && name === "STATUS_FOLLOWS_CONFLICT") {
            alert(__("Nie je možné zmeniť stav objednávky."));
          } else {
            alert(__("Neznáma chyba pri zmene stavu objednávky"));
          }
        });
    }
    return null;
  }

  handleClickExportPDF() {
    const {
      detail: { _id: orderId, invoicenr },
      supplierId,
    } = this.props;
    if (orderId) {
      this.setState({ fetchingPdf: true });
      apiEshop
        .adminGetOrderInvoicePdf(supplierId, orderId)
        .then((response) => {
          window.location = response.url;
        })
        .catch((error) => {
          alert(__(`Ľutujeme, doklad č. ${invoicenr} sa nepodarilo stiahnuť.`));
          console.error(error);
        })
        .finally(() => {
          this.setState({ fetchingPdf: false });
        });
    }
  }

  togglePacketeryModal = () => {
    this.setState((prevState) => ({
      isPacketeryLabelModalOpened: !prevState.isPacketeryLabelModalOpened,
    }));
  };

  handleClickPacketeryLabel = async () => {
    const {
      detail: { _id: orderId },
      supplierId,
    } = this.props;
    if (orderId) {
      this.setState({ fetchingPacketeryLabel: true });
      try {
        const response = await apiEshop.adminGetOrderPacketeryLabel(
          supplierId,
          orderId,
          { format: this.state.packeteryLabelFormat },
        );
        window.location = response.url;
      } catch (e) {
        alert(__("Štítok sa nepodarilo získať"));
      } finally {
        this.setState({ fetchingPacketeryLabel: false });
      }
    }
  };

  handleClickConfirmationPDF() {
    const {
      detail: { _id: orderId },
      supplierId,
    } = this.props;
    if (orderId) {
      this.setState({ fetchingConfirmationPdf: true });
      apiEshop
        .adminGetOrderConfirmationPdf(supplierId, orderId)
        .then((response) => {
          window.location = response.url;
        })
        .catch((error) => {
          alert(__(`Ľutujeme, potvrdenie o nákupe sa nepodarilo stiahnuť.`));
          console.error(error);
        })
        .finally(() => {
          this.setState({ fetchingConfirmationPdf: false });
        });
    }
  }

  renderPacketeryAddress = (address) => {
    return (
      <div>
        <div>
          <b>{__("Packeta")}</b>
        </div>
        {address.place}
        <div>
          {address.street && address.street}
          {address.zip && ` ${address.zip}`}
          {address.city && ` ${address.city}`}
          {address.country && ` ${address.country}`}
        </div>
      </div>
    );
  };

  renderBalikovoAddress = (address) => {
    return (
      <div>
        <div>
          <b>{__("Balíkovo")}</b>
        </div>
        {address.description}
        <div>
          {address.address && address.address}
          {address.zip && ` ${address.zip}`}
          {address.city && ` ${address.city}`}
          {address.countryISO && ` ${address.countryISO}`}
        </div>
      </div>
    );
  };

  renderAddress(address, delivery, detail) {
    if (delivery && delivery.type === "ZASIELKOVNA" && delivery?.payload) {
      return this.renderPacketeryAddress(delivery.payload);
    }
    if (delivery && delivery.type === "BALIKOVO" && delivery?.payload) {
      return this.renderBalikovoAddress(delivery.payload);
    }

    return (
      <div>
        {address.company}
        {address.person && <div>{address.person}</div>}
        <div>
          {address.street && address.street}
          {address.number && ` ${address.number} ,`}
          {address.zip && ` ${address.zip}`}
          {address.city && ` ${address.city}`}
        </div>
      </div>
    );
  }

  handlePrintSPSLabel(detail, appspace) {
    if (detail.spsInfo?.documentUrl) {
      window.open(detail.spsInfo.documentUrl, "_blank");
      return;
    }

    this.setState({ fetchingSPSLabel: true });

    apiEshop
      .adminCreateOrderSPSLabel(appspace, detail._id)
      .then((response) => {
        this.getOrderDetail();
        if (response.documentUrl) {
          window.open(response.documentUrl, "_blank");
        }
      })
      .catch((error) => {
        alert(
          __(
            `Ľutujeme, štítok sa nepodarilo vytlačiť. \n\n${error?.details?.name}`,
          ),
        );

        console.error(error);
      })
      .finally(() => {
        this.setState({ fetchingSPSLabel: false });
      });
  }

  renderContact(contact) {
    return (
      <div>
        {contact.email && (
          <div>
            <b>{__("Email:")}</b> {contact.email}
          </div>
        )}
        {contact.phone && (
          <div>
            <b>{__("Telefón:")}</b> {contact.phone}
          </div>
        )}
      </div>
    );
  }

  renderStatusLogItem(item, i) {
    // const { change_date, old_status, new_status, admin_user, auth_user } = item;
    // const { orderStatuses, detail: { status_log = [] } } = this.props;
    const { orderStatuses, detail } = this.props;
    const oldStatusLabel = getProp(
      this.getStatusObj(orderStatuses, item.old_status),
      ["label"],
      item.old_status,
    );
    const newStatusLabel = getProp(
      this.getStatusObj(orderStatuses, item.new_status),
      ["label"],
      item.new_status,
    );

    return (
      <StatusLog key={i} last={i === detail.status_log.length - 1}>
        <StatusLogText>
          {format(new Date(item.change_date), "dd.MM.yyyy HH:mm")}
        </StatusLogText>
        <StatusLogText>
          {oldStatusLabel}
          &nbsp;→&nbsp;
          {newStatusLabel}
        </StatusLogText>
        <StatusLogText>
          {item.admin_user &&
            `${item.admin_user.display_name} (${item.admin_user.sportnet_id})`}
          {item.auth_user &&
            `${item.auth_user.display_name} (${item.auth_user.sportnet_id})`}
        </StatusLogText>
      </StatusLog>
    );
  }

  renderSystemLogItem(item, i) {
    const { date, message, payload } = item;

    return (
      <SystemLog key={i}>
        <strong>{format(new Date(date), "dd.MM.yyyy HH:mm")}</strong>
        {": "}
        {message}
        <JsonDisplay>{JSON.stringify(payload, null, 2)}</JsonDisplay>
      </SystemLog>
    );
  }

  render() {
    if (
      !this.props.detail ||
      (this.props.detail.isFetching && !this.props.detail._id)
    ) {
      return <Loader />;
    }

    const {
      detail,
      detail: {
        delivery,
        delivery_address: deliveryAddress,
        invoice_address: invoiceAddress,
        payment,
        status,
        note,
        supplier,
        supplier: { invoice_address: supplier_address = {} },
        items,
        summary,
        created_date: createdDate,
        status_log: statusLog = [],
        log = [],
        invoicenr,
        business_id: businessId,
        vat_nr: vatNr,
        voucher,
      },
      orderStatuses,
    } = this.props;

    const { order_id: orderId, appspace } = this.props.match.params;
    const {
      receiptDetail: {
        status: receiptStatus,
        paid_date: receiptPaidDate,
        created_date: receiptCreatedDate,
        _id: receiptId,
      },
    } = this.state;

    const statusObj = this.getStatusObj(orderStatuses, status);
    const statusLabel =
      statusObj?.deliveryLabel?.[delivery.type] || statusObj.label;
    const statusFollows = getProp(statusObj, ["payload", "follows"], []);
    const statuses = statusFollows.map((item) =>
      this.getStatusObj(orderStatuses, item),
    );

    const reason = getProp(statusLog, [0, "reason"]);

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/orders`}
              title={__("Objednávky")}
            />
            <HeaderBar.Header>
              {__(`${__("Objednávka č.")} ${orderId}`)}
            </HeaderBar.Header>
            <HeaderBar.Spacer />
            <HeaderBar.Item>
              <Button onClick={this.handleClickPrint}>
                <Icon name="printTool" xxl />
              </Button>
            </HeaderBar.Item>
          </HeaderBar>
        }
      >
        <AppContext
          title={__(`${__("Objednávka č.")} ${orderId}`)}
          breadcrumbs={[
            {
              url: `/admin/${appspace}/orders`,
              name: __("Objednávky"),
            },
            { name: __(`${__("Objednávka č.")} ${orderId}`) },
          ]}
        />
        <Body>
          <OrderId>
            <strong>{__("Číslo objednávky: ")}</strong>
            {getProp(detail, ["_id"], "")}
          </OrderId>
          <PanelsWrapper>
            {getProp(detail, ["saleschannel"]) && (
              <Col hide>
                <PanelHeader>{__("Predajný kanál:")}</PanelHeader>
                <PanelBody>
                  {this.getSaleChannelLabel(getProp(detail, ["saleschannel"]))}
                </PanelBody>
              </Col>
            )}
            <Col hide>
              <PanelHeader>{__("Stav:")}</PanelHeader>
              <PanelBody>
                <StyledStatus color={getProp(statusObj, ["payload", "color"])}>
                  {statusLabel}
                </StyledStatus>
                {reason && <div>{reason}</div>}
              </PanelBody>
            </Col>
            <Col hide>
              <PanelHeader>{__("Zmeniť stav objednávky na:")}</PanelHeader>
              <PanelBody>
                <Statuses
                  deliveryType={delivery.type}
                  statuses={statuses}
                  handleChange={this.handleChangeStatus}
                  selected=""
                />
              </PanelBody>
            </Col>
            <Col hide>
              <PanelHeader>{`${__("Súbory na stiahnutie")}`}</PanelHeader>
              <PanelBody>
                <Row>
                  <SportnetCol xs={6}>
                    <Button
                      loading={this.state.fetchingConfirmationPdf}
                      basic
                      block
                      onClick={this.handleClickConfirmationPDF}
                    >
                      {__("Potvrdenie o nákupe")}
                    </Button>
                  </SportnetCol>
                  {delivery.type === "ZASIELKOVNA" && (
                    <>
                      <SportnetCol xs={6}>
                        <Button
                          loading={this.state.fetchingPacketeryLabel}
                          basic
                          block
                          onClick={this.togglePacketeryModal}
                        >
                          {__("Packeta - štítok")}
                        </Button>
                      </SportnetCol>
                      <Modal
                        handleClose={this.togglePacketeryModal}
                        isOpen={this.state.isPacketeryLabelModalOpened}
                      >
                        <ModalContent>
                          <TheSelect
                            clearable={false}
                            value={this.state.packeteryLabelFormat}
                            onChange={(format) => {
                              this.setState({ packeteryLabelFormat: format });
                            }}
                            options={packeteryLabelFormats}
                          />
                        </ModalContent>
                        <ModalActions>
                          <div>&nbsp;</div>
                          <div>
                            <Button
                              primary
                              onClick={this.handleClickPacketeryLabel}
                            >
                              {__("Získať štítok")}
                            </Button>
                            <Button onClick={this.togglePacketeryModal}>
                              {__("Zavrieť")}
                            </Button>
                          </div>
                        </ModalActions>
                      </Modal>
                    </>
                  )}
                  {invoicenr ? (
                    <SportnetCol xs={6}>
                      <Button
                        loading={this.state.fetchingPdf}
                        basic
                        onClick={this.handleClickExportPDF}
                      >
                        {`${__("Faktúra č.")}\xA0${invoicenr}`}
                      </Button>
                    </SportnetCol>
                  ) : null}
                </Row>
              </PanelBody>
            </Col>
            <Col hide>
              <PanelHeader>{__("Expedičné údaje")}</PanelHeader>
              <PanelBody>
                <ShipDetail
                  onClick={this.handleSubmitShip}
                  onChange={this.handleChangeEx}
                  shipment_id={this.state.shipment_id}
                  shipment_link={this.state.shipment_link}
                />
              </PanelBody>
            </Col>
          </PanelsWrapper>
          <PanelsWrapper>
            <Panel orderSupplier>
              <PanelHeader>{__("Dodávateľ")}</PanelHeader>
              <PanelBody>
                {supplier.name}
                {(!!supplier.contact_person || !!supplier.contactPerson) && (
                  <div>{supplier.contact_person || supplier.contactPerson}</div>
                )}
                <div>
                  {supplier_address.street && supplier_address.street}
                  {supplier_address.number && ` ${supplier_address.number} ,`}
                  {supplier_address.zip && ` ${supplier_address.zip}`}
                  {supplier_address.city && ` ${supplier_address.city}`}
                </div>
                {supplier.business_id && (
                  <div>
                    <b>IČO:</b> {supplier.business_id}
                  </div>
                )}
                {supplier.vat_nr && (
                  <div>
                    <b>DIČ/IČ DPH:</b> {supplier.vat_nr}
                  </div>
                )}
                {this.renderContact(supplier)}
              </PanelBody>
            </Panel>

            <Panel order>
              <PanelHeader>{__("Odberateľ")}</PanelHeader>
              <PanelBody>
                {this.renderAddress(invoiceAddress)}
                {businessId && (
                  <div>
                    <b>IČO:</b> {businessId}
                  </div>
                )}
                {vatNr && (
                  <div>
                    <b>DIČ/IČ DPH:</b> {vatNr}
                  </div>
                )}
                {this.renderContact(detail)}
              </PanelBody>
            </Panel>
            <Panel>
              <PanelHeader>{__("Dodacia adresa")}</PanelHeader>
              <PanelBody>
                {this.renderAddress(deliveryAddress, delivery, detail)}
                {this.renderContact(detail)}
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader>{__("Spôsob doručenia/Platby")}</PanelHeader>
              <PanelBody>
                {delivery.name} <br />
                {payment.name}
                {(delivery.type === "BALIKOVO" || delivery.type === "SPS") && (
                  <ButtonWrapper>
                    <Button
                      primary
                      loading={this.state.fetchingSPSLabel}
                      onClick={() => this.handlePrintSPSLabel(detail, appspace)}
                      type="button"
                    >
                      {__(
                        detail.spsInfo?.documentUrl
                          ? "Vytlačiť štítok (zásielka podaná)"
                          : "Podať zásielku a vytlačiť štítok",
                      )}
                    </Button>
                  </ButtonWrapper>
                )}
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader>{__("Dátum vytvorenia:")}</PanelHeader>
              <PanelBody>
                {format(new Date(createdDate), "dd.MM.yyyy HH:mm")}
              </PanelBody>
            </Panel>

            <PaymentInfo
              detail={detail}
              setInitialState={this.setInitialState}
            />

            <Panel>
              <PanelHeader>{__("Stav predpisu")}</PanelHeader>
              <PanelBody>
                {receiptCreatedDate ? (
                  <div>
                    <div>
                      {`${__("Vytvorený")} ${format(
                        new Date(receiptCreatedDate),
                        "dd.MM.yyyy HH:mm",
                      )}`}
                    </div>
                    <div>
                      <Link to={`/admin/${appspace}/receipt/${receiptId}`}>
                        {receiptId}
                      </Link>
                    </div>
                    <div>
                      {receiptStatus === "paid"
                        ? __("Vyplatený")
                        : __("Nevyplatený")}
                    </div>
                    <div>
                      {receiptPaidDate ? (
                        <PaidWrapper>
                          <Icon name="checked" success />
                          &nbsp;&nbsp;
                          {format(
                            new Date(receiptPaidDate),
                            "dd.MM.yyyy HH:mm",
                          )}
                        </PaidWrapper>
                      ) : (
                        <Icon name="delete" danger />
                      )}
                    </div>
                  </div>
                ) : (
                  __("Nevytvorený")
                )}
              </PanelBody>
            </Panel>
            {note && (
              <NotePanel>
                <PanelHeader>{__("Poznámka:")}</PanelHeader>
                <PanelBody>{note}</PanelBody>
              </NotePanel>
            )}
          </PanelsWrapper>

          <Payments
            detail={detail}
            setInitialState={this.setInitialState}
            supplierId={this.props.supplierId}
          />

          <Panel noBorders>
            <PanelHeader>{__("Produkty")}</PanelHeader>
            <OrderItemList items={items} />
            {!!voucher && (
              <OrderItemList
                items={[
                  {
                    item_id: voucher._id,
                    item_name: __("Voucher"),
                    totalprice: {
                      amount: 0,
                      amount_vat_excl: 0,
                    },
                    quantity: 1,
                    variant: {
                      code: voucher._id,
                      unitprice: {
                        amount: 0,
                        amount_vat_excl: 0,
                      },
                    },
                  },
                ]}
              />
            )}

            <TotalPrice>
              {summary && <OrderSummary summary={summary} />}
            </TotalPrice>
          </Panel>

          {statusLog.length > 0 && (
            <Panel>
              <PanelHeader>{__("História stavov")}</PanelHeader>
              {statusLog.map(this.renderStatusLogItem)}
            </Panel>
          )}

          {log.length > 0 && (
            <Panel>
              <PanelHeader>{__("Systémový log")}</PanelHeader>
              {log.map(this.renderSystemLogItem)}
            </Panel>
          )}
        </Body>
      </Layout>
    );
  }
}

const AddressProptypes = {
  company: PropTypes.string,
  person: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
};

OrderDetail.propTypes = {
  detail: PropTypes.shape({
    isFetching: PropTypes.bool,
    shipment_id: PropTypes.string,
    shipment_link: PropTypes.string,
    _id: PropTypes.number,
    delivery: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    delivery_address: PropTypes.shape({
      ...AddressProptypes,
    }),
    invoice_address: PropTypes.shape({
      ...AddressProptypes,
    }),
    payment: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    status: PropTypes.string,
    supplier: PropTypes.shape({
      name: PropTypes.string,
      vat_nr: PropTypes.string,
      business_id: PropTypes.string,
      legal_form: PropTypes.string,
      phone: PropTypes.string,
      contact_person: PropTypes.string,
      email: PropTypes.string,
      invoice_address: PropTypes.shape({
        ...AddressProptypes,
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...orderItemPropTypes,
      }),
    ),
    summary: PropTypes.shape({
      taxRates: PropTypes.arrayOf(
        PropTypes.shape({
          base: PropTypes.number,
          tax: PropTypes.number,
          vatRate: PropTypes.number,
        }),
      ),
      totalBase: PropTypes.number,
      total: PropTypes.number,
      tprice: PropTypes.number,
      tpriceBase: PropTypes.number,
      currency: PropTypes.string,
      paidbycredit: PropTypes.number,
      paidbyother: PropTypes.number,
      pricetopay: PropTypes.number,
    }),
    created_date: PropTypes.string,
    status_log: PropTypes.arrayOf(
      PropTypes.shape({
        change_date: PropTypes.string,
        old_status: PropTypes.string,
        new_status: PropTypes.string,
        admin_user: PropTypes.shape({
          display_name: PropTypes.string,
        }),
        auth_user: PropTypes.shape({
          display_name: PropTypes.string,
        }),
      }),
    ),
    log: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        message: PropTypes.string,
        payload: PropTypes.object,
      }),
    ),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      order_id: PropTypes.string,
    }),
  }).isRequired,
  supplierId: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  saleChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

OrderDetail.defaultProps = {
  detail: null,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { order_id: orderId },
    },
  } = ownProps;
  const {
    purchase: { orderStatuses },
    userAcl,
  } = state;
  return {
    detail: getOrderByIdSelector(state, orderId),
    orderStatuses: orderStatuses.codelist,
    supplierId: state.selectedOrg,
    acl: userAcl.data,
    saleChannels: saleChanellsListSelector(state),
  };
};

export default connect(mapStateToProps)(withTheme(OrderDetail));
